export const SERVER = 'https://server.maple.rs';
export const CLIENT = 'maple.rs';
// export const SERVER = 'http://localhost:18000';
// export const CLIENT = 'localhost';
export const USER_COOKIE_NAME = 'user';
export const ADMIN_COOKIE_NAME = 'admin';
export const SOUND_OPT_LOC_STOR_KEY = 'sound';
export const TOOLTIP_OPT_LOC_STOR_KEY = 'tooltip';
export const GAME_NAME = 'ITCG';
export const MAX_WIDTH = '1400px';
