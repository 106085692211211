import { Ctx } from 'boardgame.io';
import React from 'react';

import { Location, getTargetLocations } from './target';
import { BLANK_CARDNAME, NonCharacter } from './card';
import { GameState, PlayerState } from './game';
import { State as BoardState } from './itcgBoard';
import { Styles, ITCGCard, ITCGCardback } from './itcgCard';
import { Decision, isSelectable } from './stack';

export type DialogBoxOpts =
  | 'discard'
  | 'deck'
  | 'oppdiscard'
  | 'oppdeck'
  | 'temporary'
  | 'opptemporary';

interface DialogProp {
  G: GameState;
  ctx: Ctx;
  playerState: PlayerState;
  opponentState: PlayerState;
  curDecision?: Decision;
  currentPlayer: boolean;
  select: () => any;
  updateBoard: (state: BoardState) => any;
  dialogBox?: DialogBoxOpts;
}

const baseStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '70vw',
  height: '60vh',
  borderRadius: '1em',
  border: 'dashed',
  textAlign: 'center',
  textShadow:
    '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
  backgroundColor: 'rgba(84, 84, 84, 0.5)',
  fontSize: '2vw',
  overflow: 'hidden',
};

const titleStyle: React.CSSProperties = {
  padding: '2%',
};

const selectionStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100%',
  alignItems: 'center',
};

const buttonStyle: React.CSSProperties = {
  alignSelf: 'center',
  width: '20%',
  fontSize: '1vw',
  borderRadius: '0.5em',
};

export class ITCGDialog extends React.Component<DialogProp> {
  getResponsiveState(): DialogBoxOpts | undefined {
    const usableLocations = ['deck', 'discard']; //, 'temporary'];
    if (!this.props.currentPlayer) return this.props.dialogBox;

    const targetLocations = this.props.curDecision
      ? getTargetLocations(this.props.curDecision.target)
          .map((location) => location.toLowerCase())
          .filter((val) => usableLocations.includes(val))
      : [];
    if (targetLocations.length > 0) {
      if (this.props.dialogBox && targetLocations.includes(this.props.dialogBox)) {
        return undefined;
      } else if (this.props.dialogBox) {
        return this.props.dialogBox;
      }

      return targetLocations[0] as DialogBoxOpts;
    }

    return this.props.dialogBox;
  }

  getMessage(opt: DialogBoxOpts) {
    switch (opt) {
      case 'discard':
        return 'Discard Pile';
      case 'oppdiscard':
        return "Opponent's Discard Pile";
      case 'deck':
        return 'Deck';
      case 'oppdeck':
        return "Opponent's Deck";
      case 'temporary':
        return 'Temporary Zone';
      case 'opptemporary':
        return 'Temporary Zone';
    }
  }

  getDisplayPile(opt: DialogBoxOpts): NonCharacter[] {
    switch (opt) {
      case 'discard':
        return this.props.playerState.discard;
      case 'oppdiscard':
        return this.props.opponentState.discard;
      case 'deck':
        return this.props.playerState.deck;
      case 'oppdeck':
        return this.props.opponentState.deck;
      case 'temporary':
        return this.props.playerState.temporary;
      case 'opptemporary':
        return this.props.opponentState.temporary;
    }
  }

  getLocation(opt: DialogBoxOpts): Location {
    switch (opt) {
      case 'discard':
      case 'oppdiscard':
        return this.props.currentPlayer ? Location.Discard : Location.OppDiscard;
      case 'deck':
      case 'oppdeck':
        return this.props.currentPlayer ? Location.Deck : Location.OppDeck;
      case 'temporary':
      case 'opptemporary':
        return this.props.currentPlayer ? Location.Temporary : Location.OppTemporary;
    }
  }

  finish() {
    this.props.updateBoard({ dialogBox: this.getResponsiveState() });
  }

  render() {
    const dialogState = this.getResponsiveState();
    if (dialogState === undefined) return null;

    const message = this.getMessage(dialogState);
    const pile = this.getDisplayPile(dialogState);

    const deck = pile.map((card) => {
      const styles: Styles[] = ['expandStyle'];
      const skill: Styles[] = ['expandStyle'];

      if (card.selected) {
        styles.push('selectedBorderTop');
        skill.push('selectedBorderBot');
      } else if (
        this.props.curDecision &&
        isSelectable(
          this.props.G,
          this.props.ctx,
          this.props.playerState,
          this.props.curDecision,
          card
        )
      ) {
        styles.push('selectableBorderTop');
        skill.push('selectableBorderBot');
      }

      if (card.name !== BLANK_CARDNAME) {
        return (
          <div className={'padding'}>
            <ITCGCard
              move={this.props.select}
              styles={styles}
              skill0={skill}
              location={this.getLocation(dialogState)}
              card={card}
              key={card.key}
            />
          </div>
        );
      } else {
        return (
          <div className={'padding'}>
            <ITCGCardback key={card.key} styles={['expandStyle']} />
          </div>
        );
      }
    });

    return (
      <div style={baseStyle}>
        <div style={titleStyle}>{message}</div>
        <div style={selectionStyle} className={'flex-center row'}>
          {deck}
        </div>
        <button style={buttonStyle} onClick={() => this.finish()}>
          Done
        </button>
      </div>
    );
  }
}
